// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
 /** primary **/
 --ion-color-primary: #45a9bf;
 --ion-color-primary-rgb: 56, 128, 255;
 --ion-color-primary-contrast: #ffffff;
 --ion-color-primary-contrast-rgb: 255, 255, 255;
 --ion-color-primary-shade: #ffffff;
 --ion-color-primary-tint: #fbfbfb;

 /** secondary **/
 --ion-color-secondary: rgb(136, 160, 186);
 --ion-color-secondary-rgb: 12, 209, 232;
 --ion-color-secondary-contrast: #ffffff;
 --ion-color-secondary-contrast-rgb: 255, 255, 255;
 --ion-color-secondary-shade: #ffffff;
 --ion-color-secondary-tint: #ffffff;

 /** tertiary **/
 --ion-color-tertiary: #ffffff;
 --ion-color-tertiary-rgb: 112, 68, 255;
 --ion-color-tertiary-contrast: #ffffff;
 --ion-color-tertiary-contrast-rgb: 255, 255, 255;
 --ion-color-tertiary-shade: #ffffff;
 --ion-color-tertiary-tint: #ffffff;

 /** success **/
 --ion-color-success: #10dc60;
 --ion-color-success-rgb: 16, 220, 96;
 --ion-color-success-contrast: #ffffff;
 --ion-color-success-contrast-rgb: 255, 255, 255;
 --ion-color-success-shade: #0ec254;
 --ion-color-success-tint: #28e070;

 /** warning **/
 --ion-color-warning: #363940;
 --ion-color-warning-rgb: #363940;
 --ion-color-warning-contrast: #ffffff;
 --ion-color-warning-contrast-rgb: #363940;
 --ion-color-warning-shade: #363940;
 --ion-color-warning-tint: #363940;

 /** danger **/
 --ion-color-danger: #d9877300;
 --ion-color-danger-rgb: 245, 61, 61;
 --ion-color-danger-contrast: #ffffff;
 --ion-color-danger-contrast-rgb: 255, 255, 255;
 --ion-color-danger-shade: #d9877300;
 --ion-color-danger-tint: #d9877300;

 /** dark **/
 --ion-color-dark: #D98773;
 --ion-color-dark-rgb: 34, 34, 34;
 --ion-color-dark-contrast: #ffffff;
 --ion-color-dark-contrast-rgb: 255, 255, 255;
 --ion-color-dark-shade: #D98773;
 --ion-color-dark-tint: #D98773;

 /** medium **/
 --ion-color-medium: #7388D9;
 --ion-color-medium-rgb: 152, 154, 162;
 --ion-color-medium-contrast: #ffffff;
 --ion-color-medium-contrast-rgb: 255, 255, 255;
 --ion-color-medium-shade: #7388D9;
 --ion-color-medium-tint: #7388D9;

 /** light **/
 --ion-color-light: #88a0ba;
 --ion-color-light-rgb: 244, 244, 244;
 --ion-color-light-contrast: #ffffff;
 --ion-color-light-contrast-rgb: 0, 0, 0;
 --ion-color-light-shade: #88a0ba;
 --ion-color-light-tint: #88a0ba;

 /** alert **/
 --ion-color-alert: #45a9bf;
 --ion-color-alert-rgb: 244, 244, 244;
 --ion-color-alert-contrast: #000000;
 --ion-color-alert-contrast-rgb: 100, 100, 100;
 --ion-color-alert-shade: #000000;
 --ion-color-alert-tint: #000000;

 --ion-background-color:#212226;
 --ion-text-color:#D5D5D5;


}
@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #45a9bf;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #ffffff;
  --ion-color-primary-tint: #fbfbfb;

  --ion-color-secondary: #88a0ba;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #ffffff;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #ffffff;
  --ion-color-tertiary-tint: #ffffff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #363940;
  --ion-color-warning-rgb: #363940;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: #363940;
  --ion-color-warning-shade: #363940;
  --ion-color-warning-tint: #363940;

  --ion-color-danger: #d9877300;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d9877300;
  --ion-color-danger-tint: #d9877300;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #7388D9;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #7388D9;
  --ion-color-medium-tint: #7388D9;

  --ion-color-light: #88a0ba;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #88a0ba;
  --ion-color-light-tint: #88a0ba;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
