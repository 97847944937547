/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.welcome-card img {
    max-height: 35vh;
    overflow: hidden;
  }
  
  .card-home {
    background-color: #363940;
    border-radius: 20px;
    
  }
  
  .ion-color.sc-ion-card-ios-h{
   background: #363940;
   margin-bottom: 40px;
   margin-top: 21px;
  }
  
  .card-home img{
    border-radius: 10px;
    height: 105px;
    width: 106px;
    margin: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .card-home h1{
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: #D5D5D5;
    font-size: 16px;
    font-weight: bold;
    opacity: 0.5;
    margin: 5px;
    padding-top:0px ;
  }
  
  .top-bar{
    background-color: #9FCBF0!important; 
  }
  .fab{
    width: 65px;
  height: 67px;
  }
  .icondisc{
    width: 31px;
  height: 37px;
  }
  .textos{
    margin:auto;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
  }
  .button-native {
    margin-left: auto !important;
    margin-right: auto!important;
    width: 92% !important;
    --border-radius: 20px;
    margin: 0px;
}
.card{
    background-color: #363940;
      margin: 13px;
      border-radius:10px;
      box-shadow: 0px 3px 6px #00000029;
      clear: both;
      display: auto;
      position:auto;
    }
    
  
    .images{
      display: block;
      margin:20px !important;
      margin-left: auto !important;
      margin-right: auto !important;
      max-width: 90% !important;
    }
   
    
    .sub-titulo-p{
      margin:14px !important;
      margin-bottom: 1px !important;
      margin-top: 14px !important;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
    }
    .textos{
      margin-top: 10px !important;
      margin-left: 14px !important;
      margin-right: 14px !important;
      margin-bottom: 16px !important;
      font-family: 'Roboto', sans-serif;
      font-size: 15px !important;
      text-align: left!important;
    }
  
    .textosb{
      margin: auto;
      font-family: 'Roboto', sans-serif;
      font-size: 15px !important;
      text-align: center!important;
    }
    .textosr{
      margin:14px !important;
      margin-bottom: 1px !important;
      margin-top: 14px !important;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
    }
    .textosrc{
      margin:14px !important;
      margin-bottom: 14px !important;
      margin-top: 14px !important;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
    }
    .textosrt{
      margin-top: 20px !important;
      margin-left: 14px !important;
      margin-right: 14px !important;
      margin-bottom: 16px !important;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      color: #45a9bf;
      text-transform: uppercase;
    }
    
    .button-native {
      margin-left: auto !important;
      margin-right: auto!important;
      width: 92% !important;
      --border-radius: 20px;
      margin: 0px;
  }
    .iconebut{
      height: 16px !important;
      width: 16px !important;
      
    }
    .button-native2 {
      margin-left: auto !important;
      margin-right: auto!important;
      width: 100% !important;
      --border-radius: 8px;
      margin-top: 20px !important;
      height: 34px;
  }
  .textosb2{
    margin: auto;
    font-family: 'Roboto', sans-serif;
    font-size: 10px !important;
    text-align: center!important;
    font-weight: bold !important;
  }
  .sepaco{
    height: 50%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .conter{
    height: 30%;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    margin-top: 3px;
  }
  .conterdiv{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #121212;
    color: white;
    text-align: center;
  }